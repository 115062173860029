import { useEffect, useState } from 'react'

import { sentryLogging } from 'sentry-utils/logging'

const allowedHostEndings = [
  'bluelagoon.com',
  'bluelagoon.dev',
  'bluelagoon.vercel.app',
]

/**
 * This hook is used to protect against reverse proxy attacks.
 * It checks if the current hostname ends with the allowed hostnames.
 * If it doesn't, it logs the event to Sentry and redirects the user to the main page.
 */

export const useReverseProxyProtection = () => {
  const [isFraud, setIsFraud] = useState(false)
  useEffect(() => {
    const isAllowed =
      allowedHostEndings.some(ending =>
        window?.location?.host?.endsWith(ending)
      ) || window?.location?.hostname === 'localhost'

    if (!isAllowed) {
      setIsFraud(true)
      sentryLogging({
        team: 'team-frontend-infrastructure',
        message: `Reverse proxy attack detected. Hostname: ${window?.location?.hostname}`,
        extras: {
          hostname: window?.location?.hostname,
          url: window?.location?.href,
        },
      })
    }
  }, [])

  return { isFraud }
}
