import type { ElementType, HTMLAttributes, ReactNode } from 'react'
import kebabCase from 'lodash/kebabCase'
import styled, {
  css,
  type CSSProperties,
  type FlattenSimpleInterpolation,
} from 'styled-components'
// styled-components-breakpoint v.2.0.2 does not have types and v3 preview has types but development was dropped 5 years ago
import { map } from 'styled-components-breakpoint'

import { breakpoints, type BreakpointMap } from './utils/breakpoints'
import { spacing } from './utils/spacing'

// MARK: Types

type StyleProp<T extends keyof CSSProperties> =
  | CSSProperties[T]
  | BreakpointMap<CSSProperties[T]>

type StyleProps = {
  position?: StyleProp<'position'>
  bottom?: StyleProp<'bottom'>
  left?: StyleProp<'left'>
  right?: StyleProp<'right'>
  top?: StyleProp<'top'>
  zIndex?: StyleProp<'zIndex'>
  maxWidth?: StyleProp<'maxWidth'>
  margin?: StyleProp<'margin'>
  marginTop?: StyleProp<'marginTop'>
  marginRight?: StyleProp<'marginRight'>
  marginBottom?: StyleProp<'marginBottom'>
  marginLeft?: StyleProp<'marginLeft'>
  padding?: StyleProp<'padding'>
  paddingTop?: StyleProp<'paddingTop'>
  paddingRight?: StyleProp<'paddingRight'>
  paddingBottom?: StyleProp<'paddingBottom'>
  paddingLeft?: StyleProp<'paddingLeft'>
  textAlign?: StyleProp<'textAlign'>
  $height?: StyleProp<'height'>
  $width?: StyleProp<'width'>
  minWidth?: StyleProp<'minWidth'>
  minHeight?: StyleProp<'minHeight'>
  display?: StyleProp<'display'>
  opacity?: StyleProp<'opacity'>
  gridArea?: StyleProp<'gridArea'>
  gridTemplateColumns?: StyleProp<'gridTemplateColumns'>
  gap?: StyleProp<'gap'>
  rowGap?: StyleProp<'rowGap'>
  flex?: StyleProp<'flex'>
  flexDirection?: StyleProp<'flexDirection'>
  flexBasis?: StyleProp<'flexBasis'>
  flexShrink?: StyleProp<'flexShrink'>
  flexWrap?: StyleProp<'flexWrap'>
  justifyContent?: StyleProp<'justifyContent'>
  alignItems?: StyleProp<'alignItems'>
  boxShadow?: StyleProp<'boxShadow'>
  background?: StyleProp<'background'>
  color?: StyleProp<'color'>
  overflow?: StyleProp<'overflow'>
  borderRadius?: StyleProp<'borderRadius'>
}

export type CssVarsProp = Record<
  `--${string}`,
  string | number | BreakpointMap<string | number>
>

export type BoxProps = {
  as?: ElementType
  children?: ReactNode
  cssVars?: CssVarsProp
  className?: string
} & StyleProps &
  HTMLAttributes<HTMLDivElement>

// MARK: Constants

const cssProps: Record<keyof StyleProps, keyof CSSProperties> = {
  position: 'position',
  bottom: 'bottom',
  left: 'left',
  right: 'right',
  top: 'top',
  zIndex: 'zIndex',
  maxWidth: 'maxWidth',
  margin: 'margin',
  marginTop: 'marginTop',
  marginRight: 'marginRight',
  marginBottom: 'marginBottom',
  marginLeft: 'marginLeft',
  padding: 'padding',
  paddingTop: 'paddingTop',
  paddingRight: 'paddingRight',
  paddingBottom: 'paddingBottom',
  paddingLeft: 'paddingLeft',
  textAlign: 'textAlign',
  $width: 'width',
  minWidth: 'minWidth',
  minHeight: 'minHeight',
  $height: 'height',
  display: 'display',
  opacity: 'opacity',
  gridArea: 'gridArea',
  gridTemplateColumns: 'gridTemplateColumns',
  gap: 'gap',
  rowGap: 'rowGap',
  flex: 'flex',
  flexDirection: 'flexDirection',
  flexBasis: 'flexBasis',
  flexShrink: 'flexShrink',
  flexWrap: 'flexWrap',
  justifyContent: 'justifyContent',
  alignItems: 'alignItems',
  boxShadow: 'boxShadow',
  background: 'background',
  color: 'color',
  overflow: 'overflow',
  borderRadius: 'borderRadius',
}

const spacingProperties = new Set([
  'margin',
  'marginTop',
  'marginRight',
  'marginBottom',
  'marginLeft',
  'padding',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',
  'gap',
])

const defaultStyles = {
  $width: '100%',
  display: 'flex',
  flexDirection: 'column',
}

// MARK: Helper functions

const isBreakpointMap = (
  obj: unknown
): obj is BreakpointMap<string | number> => {
  if (!obj || typeof obj !== 'object') return false

  const breakpointKeys = Object.keys(breakpoints)
  const objectKeys = Object.keys(obj)

  return (
    objectKeys.length > 0 &&
    objectKeys.every(key => breakpointKeys.includes(key))
  )
}

const safeObjectEntries = <T extends Record<string, unknown>>(
  obj: T | undefined | null
): [keyof T, T[keyof T]][] =>
  obj ? (Object.entries(obj) as [keyof T, T[keyof T]][]) : []

const reorderBreakpointMap = <T,>(
  mapObj: BreakpointMap<T>
): BreakpointMap<T> => {
  const sorted: BreakpointMap<T> = {}
  const orderedBreakpoints = (
    Object.keys(breakpoints) as (keyof typeof breakpoints)[]
  ).sort((a, b) => breakpoints[a] - breakpoints[b])
  for (const bk of orderedBreakpoints) {
    if (mapObj[bk] != null) {
      sorted[bk] = mapObj[bk]
    }
  }
  return sorted
}

const applyStyles = (
  props: StyleProps,
  applyDefaultStyles?: boolean
): FlattenSimpleInterpolation => {
  const combinedStyles = applyDefaultStyles
    ? { ...defaultStyles, ...props }
    : props
  return (
    Object.keys(combinedStyles) as Array<keyof typeof combinedStyles>
  ).reduce((acc, key) => {
    const prop = combinedStyles[key]
    const cssProperty = cssProps[key]
    if (prop && cssProperty) {
      const style = isBreakpointMap(prop)
        ? map<string | number>(
            reorderBreakpointMap(prop),
            value => css`
                ${kebabCase(cssProperty)}: ${
                  spacingProperties.has(key) &&
                  typeof value === 'number' &&
                  spacing?.[value]
                    ? spacing[value]
                    : value
                };
              `
          )
        : css`
              ${kebabCase(cssProperty)}: ${
                spacingProperties.has(key) &&
                typeof prop === 'number' &&
                spacing?.[prop]
                  ? spacing[prop]
                  : prop
              };
            `
      return css`
          ${acc}
          ${style}
        `
    }
    return acc
  }, css``)
}

const renderCSSVars = (cssVars?: CssVarsProp): FlattenSimpleInterpolation => {
  if (!cssVars) {
    return css``
  }

  return css`
    ${safeObjectEntries(cssVars)
      .filter(([, val]) => typeof val === 'string' || typeof val === 'number')
      .map(([key, val]) =>
        isBreakpointMap(val)
          ? map(
              reorderBreakpointMap(val),
              v => css`
              ${key}: ${v};
            `
            )
          : css`
            ${key}: ${String(val)};
          `
      )}
  `
}

// MARK: Exports
/* The double typing is a typescript react quirk described here: https://styled-components.com/docs/api#caveat-with-function-components */
/**
 * The `Box` component is a flexible layout component that supports a wide range of CSS properties and CSS variables.
 * It is used for constructing layouts with CSS flexbox, grid, spacing, and responsive styling.
 */
export const Box: React.FC<BoxProps> = styled.div<BoxProps>`
  ${({ theme, cssVars, ...props }) => css`
    ${renderCSSVars(cssVars)}
    ${applyStyles(props, true)}
  `}
`

/**
 * The `RawBox` component is a version of `Box` without default styles, allowing full control over styles and CSS variables.
 */
export const RawBox: React.FC<BoxProps> = styled.div<BoxProps>`
  ${({ theme, cssVars, ...props }) => css`
    ${renderCSSVars(cssVars)}
    ${applyStyles(props)}
  `}
`
